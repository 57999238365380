import {LayerDef} from "./LayerDef";
import {Hill} from "./Hill";
import {HillLists, LayerDefBase} from "./LayerDefBase";
import {COLOURS} from "./Colours";
import {Style} from "ol/style";

export class MarilynLayerDef extends LayerDefBase implements LayerDef {
    private visitedStyle = LayerDefBase.buildStyle(5, COLOURS.MARILYN.VISITED);
    private unvisitedStyle = LayerDefBase.buildStyle(
        5,
        COLOURS.MARILYN.UNVISITED
    );

    protected getToggleElem(): HTMLInputElement {
        return document.getElementById("marilyn_toggle") as HTMLInputElement;
    }

    isVisible(): boolean {
        return false;
    }

    setElementText() {
        const labelElem = document.getElementById(
            "marilyn_label"
        ) as HTMLLabelElement;
        this.setToggleLabelText(labelElem, "Marilyns", this.getCounts());
    }

    protected getUnvisitedStyle(): Style {
        return this.unvisitedStyle;
    }

    protected getVisitedStyle(): Style {
        return this.visitedStyle;
    }

    protected getLayerHills(hillLists: HillLists): Hill[] {
        const scotland = hillLists.others.filter((h) =>
            h.categories.includes("MARILYN")
        );
        const lakes = this.removeWainwrights(hillLists.lakes).filter((h) =>
            h.categories.includes("MARILYN")
        );
        return [...scotland, ...lakes];
    }

    setupGPX() {
        const elem = document.getElementById("gpx_mar");
        this.setGPXClickListener(elem, "marilyns.gpx");
    }
}
