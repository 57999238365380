import {LayerDef} from "./LayerDef";
import {Hill} from "./Hill";
import {HillLists, LayerDefBase} from "./LayerDefBase";
import {COLOURS} from "./Colours";
import {Style} from "ol/style";

export class GrahamsLayerDef extends LayerDefBase implements LayerDef {
    private visitedStyle = LayerDefBase.buildStyle(5, COLOURS.GRAHAM.VISITED);
    private unvisitedStyle = LayerDefBase.buildStyle(
        5,
        COLOURS.GRAHAM.UNVISITED
    );

    protected getToggleElem(): HTMLInputElement {
        return document.getElementById("grahams_toggle") as HTMLInputElement;
    }

    isVisible(): boolean {
        return false;
    }

    setElementText() {
        const labelElem = document.getElementById(
            "grahams_label"
        ) as HTMLLabelElement;
        this.setToggleLabelText(labelElem, "Grahams", this.getCounts());
    }

    protected getUnvisitedStyle(): Style {
        return this.unvisitedStyle;
    }

    protected getVisitedStyle(): Style {
        return this.visitedStyle;
    }

    protected getLayerHills(hillLists: HillLists): Hill[] {
        return hillLists.main.filter((h) => h.categories.includes("GRAHAM"));
    }
}
