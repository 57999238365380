import {Visit} from "./Bag";
import VectorSource from "ol/source/Vector";
import {Polyline} from "ol/format";
import {Stroke, Style} from "ol/style";
import {Feature} from "ol";

const polyline = new Polyline();
const STRAVA_STYLE = new Style({
    stroke: new Stroke({color: "#ff0000", width: 2}),
});

export function loadStravaTraces(
    hillId: number,
    visits: Visit[],
    source: VectorSource
) {
    const withStrava = visits.filter((v) => v.strava);
    selectedHill = hillId;
    if (withStrava.length) {
        const stravaIds = new Set<string>();

        withStrava.forEach((v) => {
            stravaIds.add(v.strava);

            if (activityMap.has(v.strava)) {
                return;
            }

            fetch(`activity/${v.strava}`)
                .then((r) => {
                    r.json().then((j) => {
                        const feature = polyline.readFeature(
                            j.map.summary_polyline,
                            {
                                dataProjection: "EPSG:4326",
                                featureProjection: "EPSG:3857",
                            }
                        );
                        feature.setStyle(STRAVA_STYLE);
                        activityMap.set(v.strava, feature);
                        if (selectedHill === hillId) {
                            source.addFeature(feature);
                        }
                    });
                })
                .catch((e) => {
                    console.error(e);
                });
        });

        activityMap.forEach((f, k) => {
            if (!stravaIds.has(k)) {
                source.removeFeature(f);
                activityMap.delete(k);
            }
        });
    }
}

let selectedHill = 0;
const activityMap = new Map<string, Feature>();
