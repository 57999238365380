import {LayerDef} from "./LayerDef";
import {Hill} from "./Hill";
import {HillLists, LayerDefBase} from "./LayerDefBase";
import {COLOURS} from "./Colours";
import {Style} from "ol/style";
import {Map} from "ol";

export class OutlierLayerDef extends LayerDefBase implements LayerDef {
    private visitedStyle = LayerDefBase.buildStyle(5, COLOURS.CORBETT.VISITED);
    private unvisitedStyle = LayerDefBase.buildStyle(
        5,
        COLOURS.CORBETT.UNVISITED
    );

    protected getToggleElem(): HTMLInputElement {
        return document.getElementById("out_toggle") as HTMLInputElement;
    }

    isVisible(): boolean {
        return false;
    }

    setElementText() {
        const labelElem = document.getElementById(
            "out_label"
        ) as HTMLLabelElement;
        this.setToggleLabelText(labelElem, "Wainoutliers", this.getCounts());
    }

    protected getUnvisitedStyle(): Style {
        return this.unvisitedStyle;
    }

    protected getVisitedStyle(): Style {
        return this.visitedStyle;
    }

    protected getLayerHills(hills: HillLists): Hill[] {
        return hills.lakes.filter((h) =>
            h.categories.includes("WAINWRIGHT_OUTLIER")
        );
    }
}
