import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {RenderCounts} from "./Renderer";
import {Hill} from "./Hill";
import {Visit} from "./Bag";
import {Fill, Stroke, Style} from "ol/style";
import CircleStyle from "ol/style/Circle";
import {Feature} from "ol";
import {LineString, Point} from "ol/geom";
import {fromLonLat} from "ol/proj";
import {GPXBuilder} from "./GPXBuilder";

export abstract class LayerDefBase {
    protected abstract getToggleElem(): HTMLInputElement;
    protected abstract getVisitedStyle(): Style;
    protected abstract getUnvisitedStyle(): Style;
    protected abstract getLayerHills(hills: HillLists): Hill[];

    private source: VectorSource;
    private layer: VectorLayer<any>;
    private counts: RenderCounts;
    private layerHills: Hill[];
    private visits: Visit[];

    setSource(source: VectorSource) {
        this.source = source;
    }

    setLayer(layer: VectorLayer<any>) {
        this.layer = layer;
    }

    getLayer(): VectorLayer<any> {
        return this.layer;
    }

    getSource(): VectorSource {
        return this.source;
    }

    getCounts(): RenderCounts {
        return this.counts;
    }

    getHills(): Hill[] {
        return this.layerHills;
    }

    setupGPX(): void {
        //Do nothing by default
    }

    render(hills: HillLists, visits: Visit[]) {
        this.layerHills = this.getLayerHills(hills);
        this.visits = visits;
        this.drawLines(hills.main, this.layerHills);
        this.counts = this.doRender(this.layerHills, visits);
    }

    private drawLines(hills: Hill[], layerHills: Hill[]): void {
        const hillMap = new Map<number, Hill>();
        hills.forEach((h) => {
            hillMap.set(h.id, h);
        });
        layerHills
            .filter((h) => h.parent && h.parent !== h.id)
            .forEach((h) => {
                const parent = hillMap.get(h.parent);
                if (!parent) {
                    return;
                }
                const startPoint = new Point(fromLonLat([h.lon, h.lat]));
                const endPoint = new Point(
                    fromLonLat([parent.lon, parent.lat])
                );

                const feature = new Feature({
                    geometry: new LineString([
                        startPoint.getCoordinates(),
                        endPoint.getCoordinates(),
                    ]),
                });
                feature.setStyle(LayerDefBase.LINE_STYLE);
                this.getSource().addFeature(feature);
            });
    }

    private doRender(hills: Hill[], visits: Visit[]): RenderCounts {
        let total = 0;
        let done = 0;
        const visitMap = new Map<Number, Visit[]>();

        hills
            .map((h) => {
                const hillVisits = visits.filter((v) => v.ids.includes(h.id));
                visitMap.set(h.id, hillVisits);
                return h;
            })
            .sort((a, b) => {
                const aVisit = visitMap.get(a.id).length ? 1 : 0;
                const bVisit = visitMap.get(b.id).length ? 1 : 0;
                if (aVisit != bVisit) {
                    return aVisit - bVisit;
                }
                return a.elev - b.elev;
            })
            .forEach((h) => {
                total++;
                const hillVisits = visitMap.get(h.id);

                let iconFeature = new Feature({
                    geometry: new Point(fromLonLat([h.lon, h.lat])),
                    hill: h,
                    visits: hillVisits,
                });

                let isBagged = Boolean(hillVisits.length);
                done += isBagged ? 1 : 0;
                const markerStyle = isBagged
                    ? this.getVisitedStyle()
                    : this.getUnvisitedStyle();
                iconFeature.setStyle(markerStyle);
                this.getSource().addFeature(iconFeature);
            });
        return {done, total};
    }

    checkToggle(): void {
        const toggleElem = this.getToggleElem();
        if (toggleElem) {
            this.layer.setVisible(toggleElem.checked);
        }
    }

    protected setToggleLabelText(
        labelElem: HTMLLabelElement,
        labelText: string,
        counts: RenderCounts
    ) {
        labelElem.textContent = `${labelText} (${counts.done} / ${counts.total})`;
    }

    protected setGPXClickListener(spanElem: HTMLSpanElement, fileName: string) {
        spanElem.addEventListener("click", () => {
            const gpxBuilder = new GPXBuilder(this.layerHills, this.visits);
            const gpxString = gpxBuilder.build();
            const data = new Blob([gpxString], {type: "text/plain"});
            const url = window.URL.createObjectURL(data);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
        });
    }

    public setupToggleEvent(): void {
        const toggleElem = this.getToggleElem();
        if (toggleElem) {
            toggleElem.addEventListener("change", () => {
                this.checkToggle();
            });
        }
    }

    protected removeWainwrights(hills: Hill[]): Hill[] {
        return hills
            .filter((h) => !h.categories.includes("WAINWRIGHT"))
            .filter((h) => !h.categories.includes("WAINWRIGHT_OUTLIER"));
    }

    static buildStyle(
        radius: number,
        fill: string,
        stroke = "white",
        width = 2
    ) {
        return new Style({
            image: new CircleStyle({
                radius,
                fill: new Fill({color: fill}),
                stroke: new Stroke({color: stroke, width}),
            }),
        });
    }

    static LINE_STYLE = new Style({
        stroke: new Stroke({
            color: "#888",
            width: 1,
        }),
    });
}

export type HillLists = {
    main: Hill[];
    others: Hill[];
    lakes: Hill[];
};
