import {LayerDef} from "./LayerDef";
import {Hill} from "./Hill";
import {HillLists, LayerDefBase} from "./LayerDefBase";
import {Style} from "ol/style";
import {COLOURS} from "./Colours";

export class CorbettsLayerDef extends LayerDefBase implements LayerDef {
    private visitedStyle = LayerDefBase.buildStyle(6, COLOURS.CORBETT.VISITED);
    private unvisitedStyle = LayerDefBase.buildStyle(
        6,
        COLOURS.CORBETT.UNVISITED
    );

    protected getToggleElem(): HTMLInputElement {
        return document.getElementById("corbetts_toggle") as HTMLInputElement;
    }

    isVisible(): boolean {
        return false;
    }

    setElementText() {
        const labelElem = document.getElementById(
            "corbetts_label"
        ) as HTMLLabelElement;
        this.setToggleLabelText(labelElem, "Corbetts", this.getCounts());
    }

    protected getUnvisitedStyle(): Style {
        return this.unvisitedStyle;
    }

    protected getVisitedStyle(): Style {
        return this.visitedStyle;
    }

    protected getLayerHills(hillLists: HillLists): Hill[] {
        return hillLists.main.filter((h) => h.categories.includes("CORBETT"));
    }
}
