import {Hill} from "./Hill";
import {Visit} from "./Bag";
import {COLOURS, HillCategory} from "./Colours";

export class GPXBuilder {
    private hills: Hill[];
    private visits: Visit[];

    constructor(hills: Hill[], visits: Visit[]) {
        this.hills = hills;
        this.visits = visits;
    }

    public build(): string {
        const points = this.hills.map((h) => {
            const isBagged = this.visits.some((v) => v.ids.includes(h.id));
            const colourCategory = this.getColourCategory(h);
            const colour =
                COLOURS[colourCategory][isBagged ? "VISITED" : "UNVISITED"];
            const catStr = h.categories.join(", ");
            return `  <wpt lat='${h.lat}' lon='${h.lon}'><ele>${h.elev}</ele><name>${h.name}</name><desc>ID: ${h.id}\nCategories: ${catStr}\nBagged: ${isBagged}</desc><extensions><color>${colour}</color></extensions></wpt>`;
        });
        return `<?xml version='1.0' encoding='UTF-8'?><gpx version='1.1'><name>ScotlandBagging</name>\n${points.join(
            "\n"
        )}\n</gpx>`;
    }

    private getColourCategory(h: Hill): HillCategory {
        if (
            h.categories.includes("MUNRO") ||
            h.categories.includes("MUNRO_TOP")
        ) {
            return "MUNRO";
        }
        if (h.categories.includes("CORBETT")) {
            return "CORBETT";
        }
        if (h.categories.includes("GRAHAM")) {
            return "GRAHAM";
        }
        if (h.categories.includes("MARILYN")) {
            return "MARILYN";
        }
        if (h.categories.includes("HUMP")) {
            return "HUMP";
        }
        if (h.categories.includes("SIMM")) {
            return "SIMM";
        }
        return "HUMP";
    }
}
